import React from 'react'

import Carousel from 'react-bootstrap/Carousel'
import carroc1 from '../../assets/carrosel/1.png'
import carroc2 from '../../assets/carrosel/2.png'
import carroc3 from '../../assets/carrosel/3.png'
import carroc4 from '../../assets/carrosel/4.png'
import carroc5 from '../../assets/carrosel/5.png'
import carroc6 from '../../assets/carrosel/6.png'
import carroc7 from '../../assets/carrosel/7.png'
import './home.style.css'

export default function Home() {
    return (
        <div className='container-fluid'>
            <div className='posicao'>
                <div className='contCarrossel'>
                    <Carousel fade>
                        <Carousel.Item className='itemCarrossel carousel-inner' interval={5000}>
                            <img src={carroc1} alt='Carrosel 1' />
                            <Carousel.Caption>
                                <h1>Proteja os Dados da Empresa</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className='itemCarrossel' interval={3000}>
                            <img src={carroc2} alt='Carrosel 2' />
                            <Carousel.Caption>
                                <h1>Seu Ambiente na Nuvem</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className='itemCarrossel' interval={3000}>
                            <img src={carroc3} alt='Carrosel 3' />
                            <Carousel.Caption>
                                <h1>Implemente a LGPD</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className='itemCarrossel' interval={3000}>
                            <img src={carroc4} alt='Carrosel 4' />
                            <Carousel.Caption>
                                <h1>Melhore a Performance de Rede</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className='itemCarrossel' interval={3000}>
                            <img src={carroc5} alt='Carrosel 5' />
                            <Carousel.Caption>
                                <h1>Interligue a Matriz com Filiais</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className='itemCarrossel' interval={3000}>
                            <img src={carroc6} alt='Carrosel 6' />
                            <Carousel.Caption>
                                <h1>Automatize a Comunicação</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item className='itemCarrossel' interval={3000}>
                            <img src={carroc7} alt='Carrosel 7' />
                            <Carousel.Caption>
                                <h1>Conecte-se com o Mundo</h1>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                </div>
            </div>
        </div>
    )
}