import './footer.style.css'

export default function Footer() {
    return (
        <div className='container-fluid'>
            <div className='contFooter'>
                <div className='logoFooter'>
                    <p>Mundo Slackware Security</p>
                    <p>2023© - Todos os direitos reservados® </p>
                </div>
            </div>
        </div>
    )
}