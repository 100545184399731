import './portfolio.estilo.css'
import Manutencao from '../../assets/manutencao.jpg'

export default function Portfolio() {
    return (
        <div className='containet-fluid'>
            <div className='contGeralPortfolio'>
                <img src={Manutencao} alt="Manutenção" />
            </div>
        </div>
    )
}