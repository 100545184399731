import React from 'react'
import Logo from '../../assets/logo.png'

import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';

import './header.style.css'

export default function Header() {
    return (
        <div className='container-fluid'>
            <Navbar className='header' expand='lg'>
                <Container>
                    <div className='headerGeral'>
                        <div>
                            <Nav.Link href='/'>
                                <img src={Logo} alt={Logo} />
                            </Nav.Link>
                        </div>
                    </div>
                    <div className='headerMenu'>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse>
                            <Nav className='me-auto'>
                                <Nav.Link href=''></Nav.Link>
                                <Nav.Link href='/QSomos'>Quem Somos</Nav.Link>
                                <Nav.Link href='/Portifolio'>Portifolio</Nav.Link>
                                <Nav.Link href='https://suporte.mundoslackware.com.br/' target='_blank'>Suporte</Nav.Link>
                                <Nav.Link href='https://hotelmundoslackware.com.br/' target='_blank'>Hospedagens</Nav.Link>
                                <Nav.Link href='https://loja.mundoslackware.com.br' target='_blank'>Loja Virtual</Nav.Link>
                                <Nav.Link href='/'>Contato</Nav.Link>
                            </Nav>
                        </Navbar.Collapse>
                    </div>
                </Container>
            </Navbar>
        </div>
    )
}