import { BrowserRouter, Routes, Route } from 'react-router-dom'

import Home from './pages/Home'
import QSomos from './pages/QSomos'
import Portifolio from './pages/Portfolio'
import Header from './components/Header'
import Footer from './components/Footer'


export default function RoutesApp() {
    return (
        <BrowserRouter>
            <Header />
            <Routes>
                <Route path='/' element={<Home />} />
                <Route path='/QSomos' element={<QSomos />} />
                <Route path='/Portifolio' element={<Portifolio />} />
            </Routes>
            <Footer />
        </BrowserRouter>
    )
}